#skills {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

#title-skills {
  margin-bottom: 1rem;
  font-size: 4rem;
}

.responsive-img {
  width: 100%;
  max-width: 3rem;
  height: auto;
  padding-bottom: 5px;
  display: block;
  margin: auto;
}

.col {
  text-align: center;
}

@media (max-width: 450px) {
  .p-skills {
    font-size: 0.65rem;
    font-weight: 1000;
  }
}
.p-skills {
  text-align: center;
}

#card-about {
  border-radius: 5px;
  padding: 10px;
  background: #d7d3c8;
  color: #28182a;
}

.h4-skills {
  background: linear-gradient(to bottom, #5d3666 95%, #5c9506 100%);
  width: fit-content;
  margin: 10px auto 10px auto;
  padding: 5px;
  border-radius: 5px;
}
