body {
  font-family: "Metrophobic", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  color: #d7d3c8;
  overflow-x: hidden;

  width: 100%;
  max-height: 100vh;
  height: 100%;
  margin: auto;

  position: absolute;
  background-color: black;
}

.animation-exit {
  -webkit-animation: moveToLeft 1.1s ease both;
  animation: moveToLeft 1.1s ease both;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
}

.animation-enter,
.animation-enter.active {
  -webkit-animation: moveFromRight 1.2s ease both;
  animation: moveFromRight 1.2s ease both;

  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
}

@-webkit-keyframes moveFromRight {
  0% {
    -webkit-transform: scale(0.1);
    opacity: 0;
  }
  60% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes moveFromRight {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes moveToLeft {
  from {
  }
  to {
    -webkit-transform: perspective(500px) translate3d(0%, -75%, -535px);
    opacity: 0;
  }
}
@keyframes moveToLeft {
  from {
  }
  to {
    -webkit-transform: perspective(500px) translate3d(0%, -75%, -535px);
    transform: perspective(500px) translate3d(0%, -75%, -535px);
    opacity: 0;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  animation: load 1.2s infinite 0s ease-in-out;
  animation-direction: alternate;
  text-shadow: 0 0 1px white;
}

@keyframes load {
  0% {
    opacity: 0.08;

    
    letter-spacing: 5px;
  }
  100% {
  }
}
