#contact {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

#contact-title {
  font-size: 4rem;
}

#contact-text {
  text-align: justify;
}
