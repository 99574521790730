#projects {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

#title-projects {
  margin-bottom: 2rem;
  font-size: 4rem;
}

.card {
  margin-top: 15px;
  background-color: #d7d3c8 !important;
}

.card-title {
  color: #28182a;
  font-weight: 1000;
  font-size: 2rem !important;
}

.card-text {
  padding: 5px;
  text-align: justify;

  color: #5d3666;
  font-weight: 700;
}

.image-box {
  position: relative;
  margin: auto;
  overflow: hidden;
  width: auto;
}
.image-box .zoom {
  max-width: 100%;
  transition: all 0.3s;

  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}

.image-box:hover .zoom {
  transform: scale(1.3) translateY(20px);
}

.aws-btn {
  --button-default-height: 48px;
  --button-default-font-size: 14px;
  --button-default-border-radius: 6px;
  --button-horizontal-padding: 20px;
  --button-raise-level: 3px;
  --button-hover-pressure: 2;
  --transform-speed: 0.2s;
  --button-primary-color: #28182a;
  --button-primary-color-dark: #5c9506;
  --button-primary-color-light: #d7d3c8;
  --button-primary-color-hover: #5d3666;
  --button-primary-color-active: #834c8f;
  --button-primary-border: 1px solid #5c9506;

  text-decoration: none !important;
}

.icon-img {
  width: 100%;
  max-width: 3rem;
  height: auto;
  padding-bottom: 5px;
  display: block;
  margin: auto;
}
