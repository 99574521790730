#submit-button {
  padding: 5px;
  border: 1px solid #5c9506;
  background-color: #28182a;
  color: #d7d3c8;
  text-decoration: none;
  text-shadow: unset;
}

.form-control {
  background: #d7d3c8 !important;
}

#submit-button:hover {
  background-color: #5d3666;
  color: #9dcb2c;
  text-decoration: none;
}

.aws-btn {
  --button-default-height: 48px;
  --button-default-font-size: 14px;
  --button-default-border-radius: 6px;
  --button-horizontal-padding: 20px;
  --button-raise-level: 3px;
  --button-hover-pressure: 2;
  --transform-speed: 0.2s;
  --button-primary-color: #28182a;
  --button-primary-color-dark: #5c9506;
  --button-primary-color-light: #d7d3c8;
  --button-primary-color-hover: #5d3666;
  --button-primary-color-active: #834c8f;
  --button-primary-border: 1px solid #5c9506;

  text-decoration: none !important;
}
