#home {
  height: 100%;
  width: 100%;
  padding-top: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#home-title {
  color: #28182a;
  margin-top: 2.5rem;
}
.home-margin {
  margin: auto;
}

.wrapper1 {
  font-weight: 1000;

  background: linear-gradient(
    to left,
    #28182a 0%,
    #5d3666 17%,
    #a35eb3 33%,
    #d785e9 50%,
    #a35eb3 67%,
    #5d3666 83%,
    #28182a 100%
  );

  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #d7d3c8;
  background-clip: text;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip1 2s ease-in infinite;

  display: inline-block;
}

.wrapper2 {
  font-weight: 1000;

  background: linear-gradient(
    to left,
    #28182a 0%,
    #5d3666 30%,
    #a35eb3 40%,
    #d785e9 50%,
    #a35eb3 60%,
    #5d3666 70%,
    #28182a 100%
  );

  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #d7d3c8;
  background-clip: text;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip1 2s linear infinite;

  display: inline-block;
}

@keyframes textclip1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
    background-position: -200% center;
  }
}

#repulsor {
  position: absolute;

  border-radius: 15rem;
  width: 19rem;
  height: 19rem;
}

.aws-btn {
  --button-default-height: 48px;
  --button-default-font-size: 14px;
  --button-default-border-radius: 6px;
  --button-horizontal-padding: 20px;
  --button-raise-level: 3px !important;
  --button-hover-pressure: 2;
  --transform-speed: 0.2s;
  --button-primary-color: #28182a;
  --button-primary-color-dark: #5c9506;
  --button-primary-color-light: #d7d3c8;
  --button-primary-color-hover: #5d3666;
  --button-primary-color-active: #834c8f;
  --button-primary-border: 1px solid #5c9506;

  text-decoration: none !important;
}
